import { Account } from '@aurora';

export enum ChatConversationStatus
{
    OPEN = 'OPEN',
    CLOSED = 'CLOSED'
}

export enum ChatMessageStatus
{
    SENT = 'SENT',
    READ = 'READ'
}

export interface ChatConversation {
    id: string;
    accountIds?;
    objectId?: string;
    subject?: string;
    status: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface ChatCreateConversation {
    id: string;
    subject: string;
}

export interface ChatUpdateConversationById {
    id: string;
    accountIds?;
    objectId?: string;
    subject?: string;
    status?: string;
}

export interface ChatUpdateConversations {
    id?: string;
    accountIds?: string[];
    objectId?: string;
    subject?: string;
    status?: string;
}

export interface ChatCreateMessage {
    id: string;
    groupCode: string;
    objectId?: string;
    conversationId?: string;
    message: string;
}

export interface ChatUpdateMessageById {
    id: string;
    conversationId?: string;
    accountId?: string;
    status?: string;
    message?: string;
}

export interface ChatUpdateMessages {
    id?: string;
    conversationId?: string;
    accountId?: string;
    status?: string;
    message?: string;
}

export interface ChatGroup {
    id: string;
    name: string;
    code: string;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface ChatCreateGroup {
    id: string;
    name: string;
    code: string;
    description?: string;
}

export interface ChatUpdateGroupById {
    id: string;
    name?: string;
    code?: string;
    description?: string;
}

export interface ChatUpdateGroups {
    id?: string;
    name?: string;
    code?: string;
    description?: string;
}
