import { Injectable, signal, WritableSignal } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class GeneralChatService
{

    readonly showChatButton: WritableSignal<boolean> = signal(true);

    constructor() {}

    toggleChatButton() {
        this.showChatButton.set(!this.showChatButton());
    }
}
